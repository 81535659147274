import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useUrlify } from "../../hooks/useUrlify";

const ConsentKnowMore = props => {
  const { title, description } = props;
  const { urlify } = useUrlify();

  const urlifiedDescription = urlify(description);

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">{urlifiedDescription}</Typography>
      </Box>
    </Box>
  );
};

ConsentKnowMore.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ConsentKnowMore;
