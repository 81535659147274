/* eslint-disable import/prefer-default-export */
import React from "react";

const URL_REGEX = /(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
const EMAIL_REGEX = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gm;
const LINK_TAG = "%%%";
const MATCHINGTAGS = `(?<=${LINK_TAG})`;

export const useUrlify = () => {
  const urlify = text => {
    const links = text.match(URL_REGEX);
    const emails = text.match(EMAIL_REGEX);

    const textWithLinkTags = text
      .replace(URL_REGEX, urlAsText => `${LINK_TAG}${urlAsText}${LINK_TAG}`)
      .replace(
        EMAIL_REGEX,
        emailAsText => `${LINK_TAG}${emailAsText}${LINK_TAG}`,
      );

    const splittedText = textWithLinkTags
      .split(new RegExp(MATCHINGTAGS))
      .filter(e => typeof e === "string");

    if (links || emails) {
      return splittedText.map((item, index) => {
        const textChunk = item.replaceAll(LINK_TAG, "");

        if (emails && emails.includes(textChunk)) {
          return (
            <a
              href={`mailto:${textChunk}`}
              target="_blank"
              rel="noopener noreferrer"
              key={`key-link-${index.toString()}`}
            >
              {textChunk}
            </a>
          );
        }

        if (links && links.includes(textChunk)) {
          return (
            <a
              href={
                textChunk.startsWith("http")
                  ? textChunk
                  : `https://${textChunk}`
              }
              target="_blank"
              rel="noopener noreferrer"
              key={`key-link-${index.toString()}`}
            >
              {textChunk}
            </a>
          );
        }
        return textChunk;
      });
    }

    return text;
  };

  return { urlify };
};
